
var customCollapse = {
    init: function () {
        var coll = document.getElementsByClassName("js-collapse");
        var i;
        for (i = 0; i < coll.length; i++) {
            coll[i].addEventListener("click", function() {
                this.classList.toggle("active");
                var content = this.nextElementSibling;
                if (content.style.maxHeight){
                    content.style.maxHeight = null;
                } else {
                    content.style.maxHeight = content.scrollHeight + "px";
                } 


               
                // if (content.style.maxWidth){
                // content.style.maxWidth = null;
                // } else {
                // content.style.maxWidth = "100%";
                // } 
            });
        }
    }
}