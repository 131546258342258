var fixedheader = {
    init: function(){

        var screenwidth = $(window).outerWidth()
        //var headerheight = $('header').outerHeight();
        var headerNavbarheight = $('header .navbar ').outerHeight();
		// var navtopHeight = $('.p-nav__top').outerHeight();
		// var totalHeaderheight = -(headerheight - navtopHeight);

 		if (screenwidth <= 767) {  
			$('main').css('margin-top',headerNavbarheight);         
			$(window).bind('scroll', function () {
				var num = 450;
				if ($(window).scrollTop() > num) {
					$('header').addClass('shrink');
					// $('header').css('margin-top',totalHeaderheight);
				} else {
					$('header').removeClass('shrink');
					// $('header').css('margin-top','inherit');
				}
			});
		} 
		else { 
			$('main').css('margin-top','inherit');   
			$('header').removeClass('shrink');
		}
    }
}