var mobileFilter = {
    init: function () {
        var screenwidth = $(window).outerWidth()
        if (screenwidth <= 479) {
            $(".noMobileFilter .filter").appendTo(".mobileFilter");
        }
        else {
            $(".mobileFilter .filter").appendTo(".noMobileFilter");
        }
    }
}